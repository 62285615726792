import React, { useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'rc-checkbox/assets/index.css';

import './styles/variables.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@bepro-travel/fe.shared/dist/styles.css';
import '@bepro-travel/fe.hotel/dist/styles.css';
import '@bepro-travel/fe.flight/dist/styles.css';
import '@bepro-travel/fe.package/dist/styles.css';
import '@bepro-travel/fe.dynamic/dist/styles.css';
import '@bepro-travel/fe.transfer/dist/styles.css';
import './styles/amsalem.scss';

import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import { configureAxios } from './services/config';
import { MainContext } from './contexts/MainContext';
import { Hotel } from './ts/interfaces/HotelResponse';
import { ToastContainer } from 'react-toastify';
import { i18nconfig } from './utils/i18n';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  cdnUrl,
  dynConfig,
  flightConfig,
  hotelConfig,
  mapSupplier,
  packageConfig,
  reCaptchaKey,
  transferConfig,
} from './constants/generic';
import { User } from './ts/interfaces/User';
import { FlightConfigContext } from '@bepro-travel/fe.flight';
import { HotelConfigContext, ProposalModal } from '@bepro-travel/fe.hotel';
import { TransferConfigContext } from '@bepro-travel/fe.transfer';
import { PackageConfigContext } from '@bepro-travel/fe.package';
import { DynConfigContext } from '@bepro-travel/fe.dynamic';
import { PageFooter, PageHeader, SiteConfig } from './ts/interfaces/CmsPageResponse';
import { getLoadings, getPage } from './services/cms';
import { SharedConfigContext, auth, ScriptLoader } from '@bepro-travel/fe.shared';
import { SearchHotelModel } from './ts/classes/SearchHotelModel';
import SearchBox from './components/SearchBox';
import LockPage from './components/LockPage';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  const [proposals, setProposals] = useState<Hotel[]>([]);
  const [user, setUser] = useState<User | undefined>();
  const [header, setHeader] = useState<PageHeader | undefined>();
  const [footer, setFooter] = useState<PageFooter | undefined>();
  const [siteConfig, setSiteConfig] = useState<SiteConfig | undefined>(undefined);
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'he');
  const [isAppReady, setIsAppReady] = useState(false);
  const [historyHotel, setHistoryHotel] = useState<SearchHotelModel[]>([]); // eslint-disable-line
  const [hotelViewConfig, setHotelViewConfig] = useState({
    areRoomsGrouped: false,
    areRoomsColoured: false,
    isRoomNameCleared: true,
    areRoomsFiltered: true,
    isCubeDesign: false,
    showRoomsInTab: false,
    isShortMode: false,
    isCubeDesignByEat: false,
    displayMode: 2,
    roomMode: 1,
  });

  const mainConfig = {
    proposals,
    setProposals,
    mapSupplier,
    user,
    setUser,
    header,
    setHeader,
    footer,
    setFooter,
    siteConfig,
    setSiteConfig,
    language,
    setLanguage,
  };

  const additinalConfig = {
    setFooter,
    setHeader,
    setSiteConfig,
    getPage,
  };

  const sharedConfig = {
    cdnUrl,
    user,
    setUser,
    footer,
    setFooter,
    header,
    setHeader,
    siteConfig,
    setSiteConfig,
    setLanguage,
    language,
    ProposalModal,
    proposals,
    setProposals,
    imgUs: require('./assets/img/us.png'),
    imgIs: require('./assets/img/is.png'),
    imgBg: require('./assets/img/newbg.jpg'),
    imgLogo: require('./assets/img/logo.png'),
    imgPaymentLoader: require('./assets/img/ico.png'),
    hideFlags: true,
  };

  useEffect(() => {
    (async () => {
      await configureAxios();
      const user = await auth.getUserToken();
      i18nconfig();

      setUser(user);

      setIsAppReady(true);

      await getLoadings();
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (language === 'he') {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }
  }, [language]);

  return (
    <BrowserRouter>
      <LockPage />
      <div className="app">
        {isAppReady && (
          <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
            <ScriptLoader>
              <SharedConfigContext.Provider
                value={{
                  ...sharedConfig,
                  defaultMultiSearchConfig: 'flt_htl',
                  SearchBox,
                  hotelConfig: hotelViewConfig,
                  setHotelConfig: setHotelViewConfig,
                }}
              >
                <FlightConfigContext.Provider value={{ ...flightConfig, ...additinalConfig, user }}>
                  <HotelConfigContext.Provider
                    value={{
                      ...hotelConfig,
                      user,
                      ...additinalConfig,
                      hotelConfig: hotelViewConfig,
                      setHotelConfig: setHotelViewConfig,
                    }}
                  >
                    <TransferConfigContext.Provider
                      value={{ ...transferConfig, ...additinalConfig, user }}
                    >
                      <PackageConfigContext.Provider
                        value={{ ...packageConfig, ...additinalConfig, language, user }}
                      >
                        <DynConfigContext.Provider
                          value={{
                            ...dynConfig,
                            ...additinalConfig,
                            proposals,
                            setProposals,
                            user,
                          }}
                        >
                          <MainContext.Provider value={mainConfig}>
                            <Header notOpenTabWithChildren={true} />
                            <Router />
                            <Footer />
                          </MainContext.Provider>
                        </DynConfigContext.Provider>
                      </PackageConfigContext.Provider>
                    </TransferConfigContext.Provider>
                  </HotelConfigContext.Provider>
                </FlightConfigContext.Provider>
              </SharedConfigContext.Provider>
            </ScriptLoader>
          </GoogleReCaptchaProvider>
        )}
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;

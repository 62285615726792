import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function usePreserveUTMParams() {
  const location = useLocation();
  const navigate = useNavigate();

  const savedParamsRef = useRef<URLSearchParams | null>(null);

  useEffect(() => {
    const currentParams = new URLSearchParams(location.search);
    const utmParams = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
      'gclid',
      'fbclid',
    ];

    if (!savedParamsRef.current) {
      savedParamsRef.current = new URLSearchParams();
      utmParams.forEach((param) => {
        if (currentParams.has(param)) {
          savedParamsRef.current?.set(param, currentParams.get(param)!);
        }
      });
    }

    const nextParams = new URLSearchParams(location.search);
    utmParams.forEach((param) => {
      if (savedParamsRef.current?.has(param) && !nextParams.has(param)) {
        nextParams.set(param, savedParamsRef.current?.get(param)!);
      }
    });

    if (nextParams.toString() !== currentParams.toString()) {
      navigate(`${location.pathname}?${nextParams.toString()}`, { replace: true });
    }
  }, [location, navigate]);
}

export default usePreserveUTMParams;
